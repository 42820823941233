<template>
  <div class="agent--filter">
    <client-only>
      <vue-bootstrap-typeahead
        ref="searchTypehead"
        :data="suggestions"
        v-model="currentKeyword"
        inputClass="basic--input"
        class="input--wrapper"
        :placeholder="$t('general.searchAgentPlaceholder')"
        @blur="handleBlur"
      ></vue-bootstrap-typeahead>
    </client-only>
    <div v-show="currentKeyword" class="reset--search">
      <button type="button" @click="resetSearch">
        <span class="ion-close"></span>
        <span class="reset--text">Reset Search</span>
      </button>
      <div v-show="isLoading" class="tab_disabled"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VueBootstrapTypeahead from '@/components/utils/vue-bootstrap-typeahead/VueBootstrapTypeahead';
import LodashMixin from '@/mixins/lodash';

// const SearchBox = () => import('@/components/search/search-box');
export default {
  mixins: [LodashMixin],
  components: {
    VueBootstrapTypeahead,
  },
  data: () => ({
    suggestions: [],
  }),
  computed: {
    ...mapState({
      isLoading: state => state.v2.search.isLoading,
      searchTerm: state => state.v2.search.searchTerm,
    }),
    currentKeyword: {
      get() {
        return this.$store.state.v2.search.currentKeyword;
      },
      set(value) {
        this.$store.commit('v2/search/SET_CURRENT_KEYWORD', value);
        this.$store.commit('v2/search/SET_SEARCH_TERM', value);
      },
    },
  },
  watch: {
    currentKeyword() {
      this.debouncedQuery();
      this.replaceInputValue();
    },
  },
  mounted() {
    this.debouncedQuery = this.debounce(this.searchAgent, 500);
    this.currentKeyword = this.searchTerm;
    this.replaceInputValue();
  },
  methods: {
    handleBlur() {
      this.$store.commit('v2/search/SET_FOCUS_INPUT_SEARCH', false);
      this.$store.commit('v2/search/SET_COVER_SEARCH', true);
    },
    replaceInputValue() {
      let self = this;
      if (self.$refs.searchTypehead !== undefined) {
        self.$refs.searchTypehead.inputValue = this.searchTerm;
      } else {
        setTimeout(function() {
          self.replaceInputValue();
        }, 500);
      }
    },
    resetSearch() {
      if (!this.isLoading) {
        this.currentKeyword = null;
        this.$store.commit('v2/search/SET_SEARCH_TERM', null);
        this.replaceInputValue();
        this.searchAgent();
      }
    },
    searchAgent() {
      let query = this.$route.query;
      query.query = this.currentKeyword;

      this.$store.commit('v2/search/SET_PAGE', 1);
      this.$store.dispatch('v2/search/getAgents', {
        query: this.$route.query,
        params: this.$route.params,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.agent--filter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  .input--wrapper {
    width: 400px;
  }
}
</style>
